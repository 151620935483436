import styled from '@emotion/styled'
import { colors } from 'style/theme'

const Well = styled('div')`
  border: 1px solid ${colors.gray.light};
  padding: 1rem;
  margin-bottom: 1rem;
`

export default Well
